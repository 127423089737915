// angular
import { HttpClient } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DateFnsModule } from '@angular/material-date-fns-adapter';
// libs
// Store Imports
// import { AppstoreModule } from '@nx-bundesliga/bundesliga-com/framework/store';
// TODO: ngx-i18n-router
// import { I18N_ROUTER_PROVIDERS, I18NRouterLoader } from '@ngx-language-router/core';
// import { I18NRouterConfigLoader } from '@ngx-language-router/config-loader';
// module
import { ConsoleService } from './console.service';
import { LogService } from './log.service';
import { WINDOW_PROVIDERS } from '@nx-bundesliga/bundesliga-com/framework/window';
import { Language } from '@nx-bundesliga/models';
import { ConfigMergeLoader, ConfigHttpLoader, ConfigLoader, ConfigModule, ConfigService, ConfigStaticLoader } from '@nx-bundesliga/shared/forked/ngx-config';
import { MetaLoader, MetaModule, MetaStaticLoader } from '@nx-bundesliga/bundesliga-com/framework/meta';

export const CORE_PROVIDERS: Array<any> = [ConsoleService, LogService];

export const APP_ENVIRONMENT = new InjectionToken<any>('app.environment');

// for AoT compilation
export function configFactory(http: HttpClient, environment: any): ConfigLoader {
	const localConfigLoader = new ConfigStaticLoader(environment); // API ENDPOINT (local variable)
	return new ConfigMergeLoader([localConfigLoader]).next((res: any) => new ConfigHttpLoader(http, res['endpoints']['config']['uri'], res['endpoints']['config']['uriFallbackFirebase'], res['endpoints']['config']['uriFallbackStatic'])); // SERIES EXECUTION
}

// TODO: ngx-i18n-router
// export function i18nRouterFactory(config: ConfigService, rawRoutes: Routes): I18NRouterLoader {
//   return new I18NRouterConfigLoader(config, rawRoutes, 'routes');
// }

export function metaFactory(config: ConfigService): MetaLoader {
	return new MetaStaticLoader({
		pageTitlePositioning: config.getSettings('seo.pageTitlePositioning', 10),
		pageTitleSeparator: config.getSettings('seo.pageTitleSeparator', ''),
		applicationName: config.getSettings('system.applicationName', ''),
		applicationUrl: config.getSettings('system.applicationUrl', ''),
		defaults: {
			'title': config.getSettings('seo.defaultPageTitle', ''),
			'description': config.getSettings('seo.defaultMetaDescription', ''),
			'apple-mobile-web-app-capable': 'yes',
			'format-detection': 'telephone=no',
			'robots': 'index, follow',
			'copyright': 'SEO.SITE_NAME',

			'twitter:image': config.getSettings('system.applicationUrl', '') + '/assets/share/fallback-bundesliga-2024-2025.jpg',
			'twitter:card': 'summary_large_image',
			'twitter:site': 'TWITTER.HANDLE',

			'x:image': config.getSettings('system.applicationUrl', '') + '/assets/share/fallback-bundesliga-2024-2025.jpg',
			'x:card': 'summary_large_image',
			'x:site': 'X.HANDLE',

			'og:image': config.getSettings('system.applicationUrl', '') + '/assets/share/fallback-bundesliga-2024-2025.jpg',
			'og:site_name': 'SEO.SITE_NAME',
			'og:type': 'website',

			// API expects en_EN as locale for all english content, but facebook only accepts en_US.
			'og:locale': config.getSettings('i18n.defaultLanguage.ietf', ''),
			'og:locale:alternate': config
				.getSettings('i18n.availableLanguages', [])
				.map((lang: Language) => lang.ietf)
				.toString()
		}
	});
}

@NgModule({
	imports: [ConfigModule.forRoot(), /*AppstoreModule,*/ MetaModule.forRoot(), DateFnsModule],
	providers: [
		CORE_PROVIDERS,
		WINDOW_PROVIDERS
		// I18N_ROUTER_PROVIDERS
	]
})
export class CoreModule {
	static forRoot(configuredProviders: Array<any>): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: configuredProviders
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule already loaded; import in root module only.');
		}
	}
}
